import isEmpty from 'lodash/isEmpty';
import MasterDataService from 'services/masterData';
import { createActions, createReducer } from 'redux/utils/reducerUtils';

const actions = createActions('CUSTOMER_PLATFORM/FunctionalLocationOpenHours/GET_OPEN_HOURS');

export const loadFunctionalLocationOpenHours = functionalLocation => dispatch => {
  const { load, fail, success } = actions;
  const meta = { functionalLocation };
  dispatch(load({ payload: null, meta }));
  if (isEmpty(functionalLocation)) {
    return Promise.resolve(dispatch(fail({ payload: new Error('No functional location given'), meta })));
  }
  return MasterDataService.getFunctionalLocationOpenHours(functionalLocation).then(result =>
    dispatch(success({ payload: result, meta }), error => dispatch(fail({ payload: error, meta })))
  );
};

export default createReducer(actions);
