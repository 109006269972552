import ApiRequest from 'utils/Fetch/ApiRequest';
import { SERVICE_API_PATH } from './index';

export const getFunctionalLocationOpenHours = functionalLocation =>
  ApiRequest.get({
    apiPath: `${SERVICE_API_PATH}/functional-locations/open-hours`,
    query: { functionalLocation },
    noCache: true,
  });

export const upsertFunctionalLocationOpenHours = openHours =>
  ApiRequest.put({
    apiPath: `${SERVICE_API_PATH}/functional-locations/open-hours`,
    data: openHours,
    noCache: true,
  });
