import * as additionalSales from './additionalSales';
import analyticsFunctionsV2 from './analyticsFunctionsV2';
import analyticsFunctionLogsV2 from './analyticsFunctionLogsV2';
import * as announcements from './announcements';
import * as building from './building';
import * as buildingMeta from './buildingMeta';
import * as checklists from './checklists';
import * as config from './config';
import contactMessages from './contactMessages';
import * as coords from './coords';
import * as customCharts from './customCharts';
import customersV2, { invalidateCustomerCache } from './customersV2';
import * as customerCategories from './customerCategories';
import * as customerSuppliers from './customerSuppliers';
import customViews from './customViews';
import * as classificationCodes from './classificationCodes';
import * as publicViews from './publicViews';
import equipment from './equipment';
import feedbacks from './feedbacks';
import fileContainers from './fileContainers';
import images from './images';
import * as insights from './insights';
import * as longTermPlans from './longTermPlans';
import maintenancePlans from './maintenancePlans';
import * as maintenancePlanLogs from './maintenancePlanLogs';
import * as partnerMeta from './partnerMeta';
import * as plannedMaintenance from './plannedMaintenance';
import * as publicMeta from './publicMeta';
import * as publicSensorHierarchies from './publicSensorHierarchies';
import sensorHierarchies, {
  createSensorHierarchy,
  deleteSensorHierarchy,
  updateSensorHierarchy,
} from './sensorHierarchies';
import sensorLogs from './sensorLogs';
import sensorMeta, { fetchSensorMeta } from './sensorMeta';
import sensors, { saveTagsForSensors } from './sensors';
import * as sensorTypes from './sensorTypes';
import * as serviceOrderLogs from './serviceOrderLogs';
import serviceOrders, {
  serviceOrdersCounts,
  serviceOrdersSearch,
  upsertServiceOrder,
  assignServiceOrder,
  updateServiceOrderStatus,
  updateServiceOrderStatusWithToken,
  invalidateMostServiceOrderCaches,
} from './serviceOrders';
import serviceRequests from './serviceRequests';
import * as shortUrls from './shortUrls';
import tags from './tags';
import * as functionalLocationsV2 from './functionalLocationsV2';
import * as functionalLocationOpenHours from './functionalLocationOpenHours';
import * as deviations from './deviations';

export const MASTER_DATA_PATH = '/master-data';
export const MASTER_DATA_V2_PATH = '/master-data/v2';
export const SERVICE_API_PATH = '/api';

const masterData = {
  ...additionalSales,
  ...analyticsFunctionsV2,
  ...analyticsFunctionLogsV2,
  ...announcements,
  ...building,
  ...buildingMeta,
  ...checklists,
  ...config,
  ...contactMessages,
  ...coords,
  ...customCharts,
  ...customersV2,
  invalidateCustomerCache,
  ...customerCategories,
  ...customerSuppliers,
  ...customViews,
  ...classificationCodes,
  ...publicViews,
  ...equipment,
  ...feedbacks,
  ...fileContainers,
  ...images,
  ...insights,
  ...longTermPlans,
  ...maintenancePlans,
  ...maintenancePlanLogs,
  ...partnerMeta,
  ...plannedMaintenance,
  ...publicMeta,
  ...publicSensorHierarchies,
  ...sensorHierarchies,
  createSensorHierarchy,
  updateSensorHierarchy,
  deleteSensorHierarchy,
  ...sensorLogs,
  ...sensorMeta,
  fetchSensorMeta,
  ...sensors,
  saveTagsForSensors,
  ...sensorTypes,
  ...serviceOrderLogs,
  ...serviceOrders,
  serviceOrdersCounts,
  serviceOrdersSearch,
  upsertServiceOrder,
  assignServiceOrder,
  updateServiceOrderStatus,
  updateServiceOrderStatusWithToken,
  invalidateMostServiceOrderCaches,
  ...serviceRequests,
  ...shortUrls,
  ...tags,
  ...functionalLocationsV2,
  ...functionalLocationOpenHours,
  ...deviations,
};

export default masterData;
